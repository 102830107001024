import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const ArticleContainer = ({ className, ...props }) => (
  <div className={`${styles.articleContainer} ${className}`} {...props} />
);

ArticleContainer.defaultProps = {
  className: '',
};

ArticleContainer.propTypes = {
  className: PropTypes.string,
};

export default ArticleContainer;
