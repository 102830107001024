import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import PageTitle from '../../elements/PageTitle';
import ArticleBody from '../../elements/ArticleBody';
import ArticleContainer from '../../elements/ArticleContainer';
import BackLink from '../../components/core/BackLink';
import Layout from '../../components/core/Layout';

const article = ({ data }) => {
  const content = data.markdownRemark;
  return (
    <Layout>
      <ArticleContainer>
        <PageTitle>{content.frontmatter.title}</PageTitle>
        <BackLink text="Back" />
        <ArticleBody dangerouslySetInnerHTML={{ __html: content.html }} />
      </ArticleContainer>
    </Layout>
  );
};

article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
};

export default article;

export const ArticleQuery = graphql`
  query ArticleQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
